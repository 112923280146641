<template>
  <div class="toolNavigation"  @scroll="scrollContent">
    <div class="type-area">
      <div class="crumbs-wrap">
        <crumbs :crumbs="crumbs"/>
      </div>

      <div class="toolNavigation-content" v-loading="loading" element-loading-text="拼命加载中" style="min-height: 400px;">
        <div class="subNav">
          <div class="toolNavigation-title">工具导航</div>
          <div class="sub-title" v-for="(item, index) in renderArr"
               :class="leftActiveIndex==index?'leftActiveClass':''" :key="index"
               @click="toAnchor(index,item.node)">
            {{item.name}}
          </div>
        </div>
        <div class="subNavContent">
          <div class="model-item" :id="item.node" ref="tools" v-for="(item, index) in renderArr" :key="index">
            <div class="title">{{item.name}}</div>
            <ul class="info-list">
              <li class="item-info" v-for="(ite, ind) in item.list" :key="ind" @click="toOutNetwork(ite.url)">
                <div class="top">
                  <img :src="ite.img"/>
                  <span class="item-info-title">{{ite.title}}</span>
                </div>
                <div class="item-info-info moreThanTwoOverflow">{{ite.description}}</div>
              </li>
              <!--<li class="item-info holder" v-for="i in holder"></li>-->
            </ul>
          </div>



        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { SUB_NAV } from "@/assets/js/toolNavigationInfo";
  import { sysAdvertiseList } from "@/api/request";
  import Crumbs from "@/components/common/crumbs/Crumbs";


  export default {
    name: "ToolNavigation",
    inject:['reload'],
    components: { Crumbs },
    data() {
      return {
        loading:true,
        crumbs: [
          { name: "ToolNavigation", title: "服务与支持" ,disable:true},
          { name: "ToolNavigation", title: "工具导航" }
        ],
        leftActiveIndex: 0,//左侧激活状态
        leftToolNavigation: [],//左侧列表
        renderArr:[],
        free:120,
        leftTitleArr:[],//收集左侧标题对应右侧的每个长度
      };
    },

     created(){
      this.leftToolNavigation = SUB_NAV;

       let arr = [];
       let count = 0;// 计数让 this.leftToolNavigation 完全遍历请求成功
       this.leftToolNavigation.forEach((item,index)=>{
        sysAdvertiseList({pageNo:1,pageSize:'',typeCode:item.typeCode,delFlag:1}).then(res=>{
          if(res.code==200 || res.code==0){
            count +=1;
            arr[index] = res.result.records

          }
        })
      })
      let timer = setInterval(()=>{
         if (count === 3) {
           clearInterval(timer);
           this.leftToolNavigation.map((itemAdd, index)=>{
             itemAdd.list = arr[index];
           })
           this.loading = false;
         }
       },10)


      // this.reload();
      // console.log('reload',this.leftToolNavigation);

    },
    mounted(){
      //进入工具导航不选中菜单
      this.$nextTick(()=>{
        // document.getElementById("servicesAndSupport").getElementsByTagName('div')[0].style.borderBottom = '#991419';
        // document.getElementById("servicesAndSupport").getElementsByTagName('div')[0].style.color = '#909399';
        let that = this;
        setTimeout(()=>{
          that.renderArr = that.leftToolNavigation;
        },1000)
       let timer = setInterval(()=>{
          if (that.$refs.tools && that.$refs.tools.length) {
            clearInterval(timer);
            for (let i = 0,len = that.$refs.tools.length; i< len; i++) {
              let offsetTopValue = this.$refs.tools[i].offsetTop;
              this.leftTitleArr.push(offsetTopValue)
            }
            window.addEventListener('scroll', that.scrollContent)
          }
        },1500)

      })
    },
    methods:{
      scrollContent(){
        let scroll = (document.documentElement.scrollTop || document.body.scrollTop) + this.free;
        // console.log(scroll,this.leftTitleArr);//  [120, 468, 816]  leftActiveIndex  leftTitleArr
        for (let i = 0; i < this.leftTitleArr.length - 1; i++) {
          let h1 = this.leftTitleArr[i];
          let h2 = this.leftTitleArr[i + 1]
          if (scroll >= h1 && scroll < h2) {
            this.leftActiveIndex = i;
            return
          }
        }
      },
      //  锚点跳转
      toAnchor(index,nodes){
        this.$nextTick(()=>{
          this.leftActiveIndex = index;
          let offsetTopValue = this.$refs.tools[index].offsetTop;
          // console.log(offsetTopValue);
          document.body.scrollTop = offsetTopValue - this.free; // chrome
          document.documentElement.scrollTop = offsetTopValue - this.free; // firefox
          // document.body.scrollTop = anchor.offsetTop
        })
      },
      //  跳转第三方链接
      toOutNetwork(url){
        window.open(url,'_black')
      },


    }
  };
</script>

<style lang="less" scoped>
  .moreThanTwoOverflow{
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
  }

  .subNavContent > .model-item:first-child > .title {
    margin-top: 0px !important;
  }

  .crumbs-wrap{
    position: fixed;
    top: 60px;
    z-index: 888;
  }


  .leftActiveClass{
    color: #0F80DC !important;
  }

  .toolNavigation {
    background: rgb(240, 242, 245);
    padding-bottom: 100px;
    margin-top: 60px;
    .toolNavigation-content {
      display: flex;
      justify-content: space-between;
      .subNav {
        width: 180px;
        /*height: 231px;*/
        background: #fff;
        text-align: left;
        padding-bottom: 20px;
        position: fixed;
        top: 120px;
        .toolNavigation-title {
          height: 52px;
          padding-left: 40px;
          line-height: 50px;
          font-size: 16px;
          color: #2f3133;
          background: rgba(216, 216, 216, 0);
          box-shadow: 0px 1px 0px 0px #ebedf0 inset;
          border-bottom: #EBEDF0 solid 1px;
        }
        .sub-title {
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #5c6066;
          padding-left: 40px;
          &:hover {
            cursor: pointer;
            color: #0F80DC;
          }
        }
      }
      .subNavContent {
        /*margin-left: 24px;*/
        margin-left: 204px;
        /*margin-top: 60px;*/
        padding-top: 60px;
        /*width: 100%;*/
        width: 1072px;
        /*background: salmon;*/

        .model-item {
          .title {
            height: 16px;
            font-size: 16px;
            font-family: MicrosoftYaHei;
            text-align: left;
            color: #2f3133;
            line-height: 16px;
            margin-top: 32px;

          }
          .info-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            /*align-content: flex-start;*/
            &:after{ //重要
              flex: auto;
              content: "";
              /*outline:1px solid #ff6600;*/
              width: 237px !important;
              margin-left: 10px;
            }
            .item-info {
              width: 237px;
              height: 134px;
              background: #ffffff;
              margin-top: 16px;
              margin-right: 12px;
              /*margin-right: 16px;*/
              &:hover{
                box-shadow: 0px 10px 18px 0px rgba(141,149,159,0.23);
                cursor: pointer;
              }
              .top {
                padding: 16px 12px 12px 12px;
                img {
                  width: 50px;
                  height: 50px;
                  vertical-align: middle;
                  border-radius: 50%;
                }
                .item-info-title {
                  display: inline-block;
                  height: 50px;
                  line-height: 50px;
                  margin-left: 12px;

                  font-size: 16px;
                  font-family: MicrosoftYaHei;
                  color: #2f3133;
                }
              }
              .item-info-info {
                padding: 0 12px 0 12px;
                height: 40px;
                font-size: 13px;
                font-family: MicrosoftYaHei;
                text-align: left;
                color: #8d9299;
                line-height: 20px;
              }
            }
          }


        }

      }
    }
  }

</style>
