<template>

  <div class="content">
    <!-- 面包屑组件 -->
    <div v-for="item in crumbs" class="item" ref="item">
      <span @click="jump(item)" class=""
            :class="[item.disable ? 'disable-class':'firstCrumbs']">{{item.title || "--" }}</span>
      <span class="theDivider">/</span>
    </div>
    <span class="lastCrumbs" :disable="true">{{lastCrumbs.title || ""}}</span>
  </div>

</template>

<script>
  export default {
    name: "Crumbs",
    props: ["crumbs"],
    data() {
      return {
        lastCrumbs: {}
      };
    },
    mounted() {
      let that = this;
      setTimeout(() => {
        console.log("麵包屑組件", that.crumbs);
        if (this.crumbs) {
          let lengths = that.crumbs.length;
          console.log(lengths);
          that.lastCrumbs = that.crumbs[lengths - 1];
          console.log(that.lastCrumbs);
          that.crumbs.pop();
        }
      }, 100);
    },
    methods: {
      jump(item) {
        if (item.disable) {
          return false;
        }
        if (item.name) {
          this.$router.push({ name: item.name });
        } else if (item.path) {
          this.$router.push({ path: item.path });
        } else {
          console.log(item);
        }

      }
    }
  };
</script>

<style lang="less" scoped>
  .content {
    font-size: 14px;
    display: flex;
    justify-content: start;
    height: 60px;
    line-height: 60px;
    width: 100%;
    /*background: skyblue !important;*/

    .item {

    }
  }

  .firstCrumbs {
    height: 14px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    text-align: justify;
    color: #2b2f33;
    line-height: 14px;
  }

  .firstCrumbs:hover {
    color: #005CD1;
    cursor: pointer;
  }

  .disable-class:hover {
    color: #005CD1;
    cursor: not-allowed;
  }

  .theDivider {
    margin: 0 5px;
  }

  .lastCrumbs {
    font-size: 14px;
    height: 60px;
    line-height: 60px;
    color: #8D9299;
  }

  /*.lastCrumbs:hover{*/
  /*color: #005CD1;*/
  /*}*/
</style>
