import ajax from '@/api/ajax'

//获取合作案例的标签
export const caseTag =(params)=> ajax('/system/sysArticle/case/tag',params,'GET')
//根据标签查询文章
export const caseGet =(params)=> ajax('/system/sysArticle/case/get',params,'GET')
//文章-通过id查询
export const queryById =(params)=> ajax('/system/sysArticle/queryById',params,'GET')
//文章-分页列表查询
export const mobileList =(params)=> ajax('/system/sysArticle/mobile/list',params,'GET')
//咨询-添加
export const consultingAdd =(params)=> ajax('/mobile/consult/add',params,'POST')
//广告-分页列表查询
export const sysAdvertiseList =(params)=> ajax('/system/sysAdvertise/list' ,params,'GET')
//查询相关案例
export const caseRelated =(params)=> ajax('/system/sysArticle/case/related' ,params,'GET')


